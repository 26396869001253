var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Patient Details")]),_c('small',{staticClass:"text-muted"},[_vm._v(" Enter patient details ")])])],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Form.Name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"Vincent"},model:{value:(_vm.detailPatient.name),callback:function ($$v) {_vm.$set(_vm.detailPatient, "name", $$v)},expression:"detailPatient.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Form.Last Name'),"label-for":"lastname"}},[_c('validation-provider',{attrs:{"name":"lastname","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastname","state":errors.length > 0 ? false : null,"placeholder":"Miller"},model:{value:(_vm.detailPatient.lastName),callback:function ($$v) {_vm.$set(_vm.detailPatient, "lastName", $$v)},expression:"detailPatient.lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Form.Phone'),"label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"form-control",attrs:{"id":"phone","state":errors.length > 0 ? false : null},model:{value:(_vm.formatPhone),callback:function ($$v) {_vm.formatPhone=$$v},expression:"formatPhone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"DOB","label-for":"date_birth"}},[_c('validation-provider',{attrs:{"name":"DOB","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"date_birth","config":_vm.config,"placeholder":"MM-DD-YYYY","type":"date","state":errors.length > 0 ? false : null},model:{value:(_vm.detailPatient.dateBirth),callback:function ($$v) {_vm.$set(_vm.detailPatient, "dateBirth", $$v)},expression:"detailPatient.dateBirth"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Facility","label-for":"facility"}},[_c('validation-provider',{attrs:{"name":"Facility","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"facility","disabled":"","state":errors.length > 0 ? false : null},model:{value:(_vm.detailPatient.facility),callback:function ($$v) {_vm.$set(_vm.detailPatient, "facility", $$v)},expression:"detailPatient.facility"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Room","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Form.Room'),"label-for":"room","state":errors.length > 0 ? false : null}},[_c('treeselect',{attrs:{"options":_vm.roomOptions,"normalizer":_vm.normalizer},model:{value:(_vm.detailPatient.room),callback:function ($$v) {_vm.$set(_vm.detailPatient, "room", $$v)},expression:"detailPatient.room"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false : null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"Gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',[_vm._v(_vm._s(_vm.$t('Form.Gender')))]),_c('treeselect',{attrs:{"options":_vm.genderOptions,"normalizer":_vm.normalizer,"disabled":_vm.detailPatient.type_visits_id != 3},model:{value:(_vm.detailPatient.gender),callback:function ($$v) {_vm.$set(_vm.detailPatient, "gender", $$v)},expression:"detailPatient.gender"}})]}}])})],1),_c('b-col',[_c('validation-provider',{attrs:{"name":"Visit reason","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label-for":"visit_reason"}},[_c('label',{attrs:{"for":"type_consult"}},[_vm._v(_vm._s(_vm.$t('Visit Reason')))]),_c('b-form-textarea',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.detailPatient.reason_visit),callback:function ($$v) {_vm.$set(_vm.detailPatient, "reason_visit", $$v)},expression:"detailPatient.reason_visit"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }