import { userAxios } from '../../index'

const ailmentList = async perPage => {
  try {
    return await userAxios
      .get(`insurance/previusMedicalHistory/all/${perPage}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const ailmentCreate = async data => {
  try {
    return await userAxios
      .post('insurance/previusMedicalHistory', data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const ailmentUpdate = async (id, data) => {
  try {
    return await userAxios
      .put(`insurance/previusMedicalHistory/${id}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const ailmentDelete = async id => {
  try {
    return await userAxios
      .delete(`insurance/previusMedicalHistory/${id}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const ailmentPagination = async (perPage, page) => {
  try {
    return await userAxios
      .get(`insurance/previusMedicalHistory/all/${perPage}?page=${page}`)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const ailmentFilter = async (perPage, data) => {
  try {
    return await userAxios
      .post(`insurance/previusMedicalHistory/search/${perPage}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const ailmentFilterPagination = async (perPage, page, data) => {
  try {
    return await userAxios
      .post(`insurance/previusMedicalHistory/search/${perPage}?page=${page}`, data)
      .then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}
export default {
  ailmentList,
  ailmentCreate,
  ailmentUpdate,
  ailmentDelete,
  ailmentPagination,
  ailmentFilter,
  ailmentFilterPagination,
}
